import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { List, Li } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "list"
    }}>{`List`}</h1>
    <h2 {...{
      "id": "unordered-list"
    }}>{`Unordered list`}</h2>
    <Playground __position={0} __code={'<List>\n  <Li>List element</Li>\n  <Li>List element</Li>\n  <Li>List element</Li>\n</List>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      List,
      Li,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <List mdxType="List">
    <Li mdxType="Li">List element</Li>
    <Li mdxType="Li">List element</Li>
    <Li mdxType="Li">List element</Li>
  </List>
    </Playground>
    <h2 {...{
      "id": "ordered-list"
    }}>{`Ordered list`}</h2>
    <Playground __position={1} __code={'<List as=\"ol\">\n  <Li>List element</Li>\n  <Li>List element</Li>\n  <Li>List element</Li>\n</List>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      List,
      Li,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <List as="ol" mdxType="List">
    <Li mdxType="Li">List element</Li>
    <Li mdxType="Li">List element</Li>
    <Li mdxType="Li">List element</Li>
  </List>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={List} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      